import { createSharedComposable } from '@vueuse/core'

const _useDashboard = () => {
  const isFiltersOpen = ref(false)

  return {
    isFiltersOpen
  }
}

export const useDashboard = createSharedComposable(_useDashboard)
